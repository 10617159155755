import React, { useEffect } from "react"

export default function CustomerChat() {
  useEffect(() => {
    window.fbAsyncInit = function() {
      window.FB.init({
        xfbml: true,
        version: "v10.0",
      })
    }
    ;(function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = "https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js"
      fjs.parentNode.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk")
  })
  return (
    <>
      <div id="fb-root" />
      <div
        className="fb-customerchat"
        // attribution="setup_tool"
        attribution="page_inbox"
        page_id="107300408184660"
        logged_in_greeting="Xin chào! Tôi có thể giúp gì bạn?"
        logged_out_greeting="Xin chào! Tôi có thể giúp gì bạn?"
        theme_color="#EC1C2A"
      />
    </>
  )
}
