import React, { Component } from "react"
import { enquireScreen } from "enquire-js"

import Footer from "./Footer"

import "../less/antMotionStyle.less"
import { Nav40DataSource } from "../data/checkio.data"
import Header from "./Header"
import CustomerChat from "./CustomerChat"
// import ZaloCustomerChat from "./ZaloCustomerChat"

let isMobile
enquireScreen(b => {
  isMobile = b
})

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile,
    }
  }
  componentDidMount() {
    enquireScreen(b => {
      this.setState({ isMobile: !!b })
    })
  }
  render() {
    const { children } = this.props
    return (
      <div>
        <Header dataSource={Nav40DataSource} isMobile={this.state.isMobile} />
        {children}
        <CustomerChat />
        {/* <ZaloCustomerChat /> */}
        <Footer />
      </div>
    )
  }
}

export default Layout
