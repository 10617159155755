import React from "react"
import TweenOne from "rc-tween-one"
import { Button, Menu, Space } from "antd"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { trackEvent } from "../components/utils"
import { IntlContextConsumer, changeLocale, Link } from "gatsby-plugin-intl"

const { SubMenu, Item } = Menu

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      phoneOpen: undefined,
      isMobile: false,
    }
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    let currentHideNav = window.innerWidth <= 767
    if (currentHideNav !== this.state.isMobile) {
      this.setState({ isMobile: currentHideNav })
    }
  }

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen
    this.setState({
      phoneOpen,
    })
  }

  handleChange = () => {}

  render() {
    const { dataSource } = this.props
    const { phoneOpen, isMobile } = this.state
    const { Menu: navData, wrapper, page } = dataSource
    const moment = phoneOpen === undefined ? 300 : null

    return (
      <header className={wrapper.className}>
        <div className={`${page.className}${phoneOpen ? " open" : ""}`}>
          <div {...dataSource.logo}>
            <Link to="/" onClick={() => trackEvent("/")}>
              <img width="100%" src={dataSource.logo.children} alt="img" />
            </Link>
          </div>
          {/* {isMobile && (
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick()
              }}
              onKeyDown={() => {
                this.phoneClick()
              }}
              role="button"
              tabIndex="0"
            >
              <em />
              <em />
              <em />
            </div>
          )} */}
          <TweenOne
            {...dataSource.Menu}
            animation={
              isMobile
                ? {
                    x: 0,
                    height: 0,
                    duration: 300,
                    onComplete: e => {
                      if (this.state.phoneOpen) {
                        e.target.style.height = "auto"
                      }
                    },
                    ease: "easeInOutQuad",
                  }
                : null
            }
            moment={moment}
            reverse={!!phoneOpen}
          >
            <Menu mode={isMobile ? "inline" : "horizontal"} theme="light">
              {navData.children.map(item => {
                if (item.subItem) {
                  const { subItem } = item
                  return (
                    <SubMenu
                      title={
                        <div className="header3-item-block">{item.title}</div>
                      }
                      key={item.name}
                      className={item.className}
                      popupClassName="header3-sub-item"
                    >
                      {subItem.map($item => {
                        return (
                          <Item key={$item.name}>
                            {$item.to ? (
                              <Link
                                to={$item.to}
                                onClick={() => trackEvent($item.to)}
                              >
                                {$item.text}
                              </Link>
                            ) : (
                              <OutboundLink
                                href={$item.href}
                                target={$item.target}
                              >
                                {$item.text}
                              </OutboundLink>
                            )}
                          </Item>
                        )
                      })}
                    </SubMenu>
                  )
                }
                return (
                  <Item key={item.name} className={item.className}>
                    {item.to ? (
                      <Link
                        to={item.to}
                        className="header3-item-block"
                        onClick={() => trackEvent(item.to)}
                      >
                        {item.title}
                      </Link>
                    ) : (
                      <OutboundLink
                        href={item.href}
                        className="header3-item-block"
                        target={item.target}
                      >
                        {item.title}
                      </OutboundLink>
                    )}
                  </Item>
                )
              })}
              <Item key="select-language" className="header3-select-language">
                <IntlContextConsumer>
                  {({ languages, language: currentLocale }) => (
                    <Space>
                      <Button
                        type="link"
                        style={{
                          ...styles.button,
                          border:
                            currentLocale === "vi" &&
                            "1px solid rgba(0, 0, 0, 0.1)",
                        }}
                        onClick={() => changeLocale("vi")}
                      >
                        <img
                          alt="img"
                          src="/vi.png"
                          style={{ verticalAlign: "top" }}
                        />
                        {currentLocale === "vi" && (
                          <img
                            alt="icon"
                            src="/tick.svg"
                            style={{
                              position: "absolute",
                              top: -5,
                              right: -5,
                            }}
                          />
                        )}
                      </Button>
                      <Button
                        type="link"
                        style={{
                          ...styles.button,
                          border:
                            currentLocale === "en" &&
                            "1px solid rgba(0, 0, 0, 0.1)",
                        }}
                        onClick={() => changeLocale("en")}
                      >
                        <img
                          alt="img"
                          src="/en.png"
                          style={{ verticalAlign: "top" }}
                        />
                        {currentLocale === "en" && (
                          <img
                            alt="icon"
                            src="/tick.svg"
                            style={{
                              position: "absolute",
                              top: -5,
                              right: -5,
                            }}
                          />
                        )}
                      </Button>
                    </Space>
                  )}
                </IntlContextConsumer>
              </Item>
            </Menu>
          </TweenOne>
        </div>
      </header>
    )
  }
}

export default Header

const styles = {
  button: {
    height: 28,
    padding: "5px 6px",
    position: "relative",
  },
}
