import React from "react"
import { Button } from "antd"
// import { trackCustomEvent, OutboundLink } from "gatsby-plugin-google-analytics"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { Link } from "gatsby-plugin-intl"

export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/
export const getChildrenToRender = (item, i) => {
  let tag = item.name.indexOf("title") === 0 ? "h2" : "div"
  tag = item.href ? OutboundLink : tag
  tag = item.to ? Link : tag
  let children =
    typeof item.children === "string" && item.children.match(isImg)
      ? React.createElement("img", { src: item.children, alt: "img" })
      : item.children
  if (item.name.indexOf("button") === 0 && typeof item.children === "object") {
    children = React.createElement(Button, {
      ...item.children,
    })
  }
  if (item.to) {
    return (
      <Link
        to={`${item.to}`}
        key={i.toString()}
        {...item}
        onClick={() => trackEvent(item.to)}
      >
        {children}
      </Link>
    )
  }
  return React.createElement(tag, { key: i.toString(), ...item }, children)
}

export const mapCategory = new Map([["/check-in", "Check-in"]])

export const trackEvent = key => {
  console.log(key)
  // trackCustomEvent({
  //   category: mapCategory.get(key),
  //   action: "Click",
  // })
}
